






































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import OrCircle from '@/components/OrCircle.vue'
import PublicHomePageCard from '@/components/PublicHomePageCard.vue'
import PanelLeftSVG from '@/assets/icons/panel-left.svg'
import PanelRightSVG from '@/assets/icons/panel-right.svg'

@Component({
  name: 'PublicDashboard',
  components: {
    PublicHomePageCard,
    OrCircle,
    PanelLeftSVG,
    PanelRightSVG
  }
})
export default class PublicDashboard extends Vue {
  @Prop() private loading!: boolean
  @Prop() private catalogPanelTitle!: string
  @Prop() private catalogPanelSubtitle!: string
  @Prop() private catalogPanelDesc!: string
  @Prop() private customPanelTitle!: string
  @Prop() private customPanelSubtitle!: string
  @Prop() private customPanelDesc!: string

  /* eslint-disable */
  @Emit('createPanelClick')
  public createPanelClick(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('navToPanels')
  public navToPanels(): void {}
  /* eslint-enable */
}
