






































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator'
import { DefaultPerPage, PageOptions } from '@/constants/global'

@Component({
  name: 'TableHeader'
})
export default class TableHeader extends Vue {
  @Prop({ default: DefaultPerPage }) defaultPerPage!: number

  searchKeyword = ''
  perPage = this.defaultPerPage
  perPages = PageOptions

  @Watch('searchKeyword')
  @Emit('searchItems')
  public onSearch(): string {
    return this.searchKeyword
  }

  @Watch('perPage')
  @Emit('changePerPage')
  public onChangePerPage(): number {
    return this.perPage
  }
}
