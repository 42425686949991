








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ValidationStatus'
})
export default class ValidationStatus extends Vue {
  @Prop() validationStatus!: boolean
  @Prop() size!: string
}
