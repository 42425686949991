




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'OrCircle'
})
export default class OrCircle extends Vue {
  @Prop({ default: false }) isAuthenticated!: boolean
  @Prop({ default: false }) loading!: boolean
}
