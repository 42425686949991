/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'
import config from '@/config/axios.config'
import logout from '@/helpers/logout'
import convertToDesignError from '@/helpers/convertToDesignError'

export default class APIClient {
  protected _axiosInstance: AxiosInstance
  private _config: AxiosRequestConfig

  constructor(baseUrl: string, apiClient: string) {
    if (!baseUrl) {
      throw new Error(`${apiClient} Error: API Url not defined`)
    }

    this._config = config(baseUrl)
    this._axiosInstance = axios.create(this._config)

    this._axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      function (error) {
        if (error && error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      }
    )
  }

  get axiosInstance(): AxiosInstance {
    return this._axiosInstance
  }

  public async get(endpoint: string, config = {}): Promise<AxiosResponse> {
    try {
      const response = await this._axiosInstance.get(endpoint, config)
      return response
    } catch (error) {
      const portalError = convertToDesignError(error)
      throw portalError
    }
  }

  public async post(
    endpoint: string,
    payload: any,
    config = {}
  ): Promise<AxiosResponse> {
    try {
      const response = await this._axiosInstance.post(endpoint, payload, config)
      return response
    } catch (error) {
      const portalError = convertToDesignError(error)
      throw portalError
    }
  }

  public async patch(
    endpoint: string,
    payload: any,
    config = {}
  ): Promise<AxiosResponse> {
    try {
      const response = await this._axiosInstance.patch(
        endpoint,
        payload,
        config
      )
      return response
    } catch (error) {
      const portalError = convertToDesignError(error)
      throw portalError
    }
  }

  public async delete(endpoint: string, config = {}): Promise<AxiosResponse> {
    try {
      const response = await this._axiosInstance.delete(endpoint, config)
      return response
    } catch (error) {
      const portalError = convertToDesignError(error)
      throw portalError
    }
  }
}
