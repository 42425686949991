import { GenomeReferenceFiles } from '@/types'

export function searchFiles(
  items: GenomeReferenceFiles,
  searchKeyword: string
): GenomeReferenceFiles {
  if (searchKeyword === '') {
    return items
  }
  const keyword = searchKeyword.toLowerCase()
  const searchItems = items.filter(
    item =>
      (item.files.fasta &&
        item.files.fasta.toLowerCase().indexOf(keyword) > -1) ||
      (item.files.dbSNP &&
        item.files.dbSNP.toLowerCase().indexOf(keyword) > -1) ||
      (item.files.chromInfo &&
        item.files.chromInfo.toLowerCase().indexOf(keyword) > -1) ||
      (item.num_contigs &&
        item.num_contigs.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.assembly_version &&
        item.assembly_version.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.scientific_name &&
        item.scientific_name.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.common_name &&
        item.common_name.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.genome &&
        item.genome.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.upload_status &&
        item.upload_status.toLowerCase().indexOf(keyword) > -1) ||
      (item.owner && item.owner.toString().toLowerCase().indexOf(keyword) > -1)
  )

  return searchItems
}
