



















































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { NavBarMenuItem } from '@/types'

@Component({
  name: 'NavBar'
})
export default class NavBar extends Vue {
  @Prop({ required: true }) private userName!: string
  @Prop() private isAuthenticated!: boolean
  @Prop() private navbarMenuItems!: NavBarMenuItem[]
  @Prop() private activeMenu!: number
  @Prop() private adminLoggedIn!: boolean

  public notifications: string[] = []

  /*eslint-disable */
  @Emit('designerOnClick')
  public designerOnClick(): void {}
  /* eslint-enable */

  @Emit('brandClick')
  public onBrandClicked(e: Event): Event {
    return e
  }

  @Emit('toggleSideBar')
  public onToggleSideBar(e: Event): Event {
    return e
  }

  /*eslint-disable */
  @Emit('navbarMenuItemClicked')
  public navbarMenuItemClicked() {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('login')
  public login(): void {}

  /*eslint-disable */
  @Emit('signup')
  public signup(): void {}
  /* eslint-enable */
}
