



































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PanelStatus'
})
export default class PanelStatus extends Vue {
  @Prop({ default: false }) customStatus!: boolean
  @Prop() panelStatus!: string
  @Prop() size!: string
  @Prop({ default: false }) isFileUpload!: boolean
}
