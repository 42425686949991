import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { SharePanelInput } from '@/types'

export default function ({
  panelId,
  data
}: SharePanelInput): Promise<AxiosResponse> {
  return api.post(`/panels/${panelId}/share`, data, {
    headers: authHeaders()
  })
}
