// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { GetUserData, GetUserDataInput } from '@/types'

export default function ({
  portalId
}: GetUserDataInput): Promise<AxiosResponse<GetUserData>> {
  return api.get(`/users?portal_id=${portalId}`, {
    headers: authHeaders()
  })
}

// Returns
// {
//     "user_id": 51,
//     "show_onboarding": 1
// }
