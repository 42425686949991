


























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CustomDashboardTable from '@/components/CustomDashboardTable.vue'
import PrivateHomePageCard from '@/components/PrivateHomePageCard.vue'
import OrCircle from '@/components/OrCircle.vue'
import PanelLeftSVG from '@/assets/icons/panel-left.svg'
import PanelRightSVG from '@/assets/icons/panel-right.svg'
import { getLocalTimeFormatted } from '@/helpers/getLocalDateTimeFormatter'
import {
  DNAPanel,
  GenomeReferenceFile,
  GenomeReferenceFiles,
  TableColumns
} from '@/types'

@Component({
  name: 'CustomDashboardWithTable',
  components: {
    CustomDashboardTable,
    PrivateHomePageCard,
    OrCircle,
    PanelLeftSVG,
    PanelRightSVG
  }
})
export default class CustomDashboardWithTable extends Vue {
  @Prop() private isAuthenticated!: boolean
  @Prop() private catalogPanelTitle!: string
  @Prop() private catalogPanelSubtitle!: string
  @Prop() private customPanelTitle!: string
  @Prop() private customPanelSubtitle!: string
  @Prop() private loading!: boolean
  @Prop() private customPanels!: DNAPanel[]
  @Prop() private customGenomes!: GenomeReferenceFiles
  @Prop() private customGenomeLoading!: boolean
  @Prop() private customPanelLoading!: boolean
  @Prop() private currentRouteName!: string
  @Prop({ default: true }) isRowEllipsis!: boolean
  @Prop({ default: true }) isRowEditable!: boolean

  public customPanelColumns: TableColumns = [
    {
      field: 'panel_name',
      label: 'Panel Name',
      customKey: 'panel_name',
      sortable: true,
      editable: this.isRowEllipsis || this.isRowEditable,
      sticky: true
    },
    {
      field: 'panel_id',
      label: 'ID',
      customKey: 'panel_id',
      sortable: true,
      editable: this.isRowEllipsis || this.isRowEditable
    },

    {
      field: 'genome',
      label: 'Genome',
      customKey: 'genome',
      sortable: true,
      editable: this.isRowEllipsis || this.isRowEditable
    },
    {
      field: 'num_lineitems',
      label: '# Targets',
      customKey: 'targets',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'num_amplicons',
      label: '# Amplicons',
      customKey: 'amplicons',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'pct_coverage',
      label: '% Coverage',
      customKey: 'pct_coverage',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },

    {
      field: 'date_last_modified',
      label: 'Last Updated',
      customKey: 'last_updated',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },

    {
      field: 'owner',
      label: 'Created By',
      customKey: 'created_by',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },

    {
      field: 'panel_status',
      label: 'Status',
      customKey: 'panel_status',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    }
  ]

  public genomeColumns: TableColumns = [
    {
      field: 'fasta',
      label: 'Fasta File Name',
      customKey: 'fasta',
      sortable: true,
      editable: this.isRowEllipsis || this.isRowEditable,
      sticky: true
    },
    {
      field: 'dbSNP',
      label: 'BED File Name',
      customKey: 'dbSNP',
      sortable: true,
      customSort: (
        fileA: GenomeReferenceFile,
        fileB: GenomeReferenceFile,
        isAsc: boolean
      ): number => {
        const sourceA = fileA.files.dbSNP?.toLowerCase() || ''
        const sourceB = fileB.files.dbSNP?.toLowerCase() || ''

        if (isAsc) {
          return sourceA >= sourceB ? 1 : -1
        }
        return sourceA <= sourceB ? 1 : -1
      },
      editable: this.isRowEllipsis || this.isRowEditable
    },
    {
      field: 'genome',
      label: 'Genome Name',
      customKey: 'genome',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'started_on',
      label: 'Date Uploaded',
      formatable: true,
      customFormat: (value: string): string => {
        return getLocalTimeFormatted(value)
      },
      sortable: true,
      customKey: 'started_on'
    },
    {
      field: 'owner',
      label: 'Uploaded By',
      customKey: 'owner',
      sortable: true
    },
    {
      field: 'upload_status',
      label: 'Status',
      customKey: 'upload_status',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'size',
      label: 'Genome Size',
      formatable: true,
      sortable: true,
      editable: this.isRowEllipsis || this.isRowEditable,
      customKey: 'size'
    },
    {
      field: 'common_name',
      label: 'Common Name',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'scientific_name',
      label: 'Scientific Name',
      editable: this.isRowEllipsis || this.isRowEditable,
      sortable: true
    },
    {
      field: 'assembly_version',
      label: 'Assembly Version',
      sortable: true
    },
    {
      field: 'num_contigs',
      label: '# Contigs',
      sortable: true
    }
  ]

  @Emit('searchKeyword')
  public searchKeyword(searchKeyword: string): string {
    return searchKeyword
  }

  /*eslint-disable */
  @Emit('navigateToPanels')
  public navigateToPanels(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('refresh')
  public refresh(): void {}
  /* eslint-enable */

  @Emit('downloadCustomPanel')
  public downloadCustomPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('deletePanel')
  public deletePanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('shareModalPopup')
  public shareModalPopup(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('createPanelClick')
  public createPanelClick(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('uploadCustomReferenceClick')
  public uploadCustomReferenceClick(): void {}
  /* eslint-enable */

  @Emit('copyPanel')
  public copyPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('changeActiveTab')
  public changeActiveTab(): void {}
  /* eslint-enable */
}
