










import { Component, Vue } from 'vue-property-decorator'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
@Component({
  name: 'App',
  components: {
    DefaultLayout
  }
})
export default class App extends Vue {
  get layout(): string {
    return this.$route.meta.layout || 'DefaultLayout'
  }
}
