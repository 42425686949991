






























import { Component, Vue, Watch } from 'vue-property-decorator'
import DefaultSublayout from '@/layouts/sublayouts/DefaultSublayout.vue'
import { Route } from 'vue-router'
import { namespace } from 'vuex-class'
import * as Sentry from '@sentry/browser'
import NavBar from '@/components/NavBar.vue'
import NavBarDropdown from '@/components/NavBarDropdown.vue'
import Footer from '@/components/Footer.vue'
import getUserProfile from '@/api/getUserProfile'
import * as Segment from '@/services/analytics'
import logout from '@/helpers/logout'
import getAuthTokenFromCookie from '@/helpers/getAuthTokenFromCookie'
import getAuthTokenPortalId from '@/helpers/getAuthTokenPortalId'
import showSnackbar from '@/helpers/showSnackbar'
import { env } from '@/constants/global'
import { NavBarMenuItem, User } from '@/types'

const Session = namespace('session')

@Component({
  components: {
    DefaultSublayout,
    NavBar,
    NavBarDropdown,
    Footer
  },
  name: 'DefaultLayout'
})
export default class DefaultLayout extends Vue {
  @Session.Action
  public getUserPermissions!: () => Promise<void>

  @Session.Getter
  public hasDesignerAdminAccess!: boolean

  public adminLoggedIn = false
  public isAuthenticated = false
  public user: User | null = null
  public activeMenu: null | number = null
  public navbarMenuItems: NavBarMenuItem[] = [
    {
      id: 1,
      title: 'White Glove Panels',
      href: '/admin',
      icon: 'dna'
    }
  ]

  get footerHome(): boolean {
    if (this.$route.name === 'Home' && this.getFirstName !== '') {
      return true
    }
    return false
  }

  get getFirstName(): string | null {
    if (this.user) {
      return this.user.user_metadata.firstName
    }
    return null
  }

  get authCookie(): string | null {
    return getAuthTokenFromCookie()
  }

  get sublayout(): boolean {
    return this.$route.meta.sublayout || 'DefaultSublayout'
  }

  get signUpUrl(): string {
    return env.VUE_APP_PORTAL_URL + '/signup'
  }

  get loginUrl(): string {
    return env.VUE_APP_PORTAL_URL + '/login'
  }

  public designerOnClick(): void {
    /*eslint-disable */
    this.$router.push({ name: 'Home' })
    /* eslint-enable */
  }

  public navbarMenuItemClicked(val: NavBarMenuItem): void {
    this.activeMenu = val.id
    /*eslint-disable */
    this.$router.push(val.href)
    /* eslint-enable */
  }

  public async getUserProfile(): Promise<void> {
    if (this.authCookie) {
      const portalId = getAuthTokenPortalId(this.authCookie)
      const data = await getUserProfile({
        userId: portalId
      })
      this.user = data.data
    }
  }

  private onBrandClicked(): void {
    if (env.VUE_APP_PORTAL_URL) {
      window.location.href = env.VUE_APP_PORTAL_URL
    }
  }

  private login(): void {
    window.location.href = this.loginUrl
  }

  private signup(): void {
    window.location.href = this.signUpUrl
  }

  private logout(): void {
    return logout()
  }

  public async created(): Promise<void> {
    if (this.authCookie) {
      try {
        await this.getUserPermissions()
      } catch (error) {
        showSnackbar({ message: error.message })
      }
    }
  }

  public async mounted(): Promise<void> {
    try {
      this.authCookie
        ? (this.isAuthenticated = true)
        : (this.isAuthenticated = false)
      await this.getUserProfile()
      this.initAnalytics()
    } catch (error) {
      showSnackbar({ message: error.message })
    }
  }

  public initAnalytics(): void {
    if (this.user) {
      const id = this.user.user_id
      const username = this.user.user_id
      const email = this.user.email
      if (process.env.NODE_ENV !== 'development') {
        Sentry.configureScope(scope => {
          scope.setUser({
            id,
            username,
            email,
            ip_address: this.user?.last_ip
          })
        })
      }
      // Set identity for Segment
      Segment.identify(username, this.user)
      Segment.track('Loaded Designer', this.user)
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(route: Route): void {
    if (route.path === '/admin' || route.path === '/admin/custom-genomes') {
      this.activeMenu = 1
    } else {
      this.activeMenu = null
    }
    if (route.path.includes('/admin')) {
      this.adminLoggedIn = true
    } else {
      this.adminLoggedIn = false
    }
  }
}
