import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import Cookies from 'js-cookie'
import createPersistedState from 'vuex-persistedstate'
import { panels } from './modules/panels'
import { session } from './modules/session'
import { RootState } from '@/types'
import { env } from '@/constants/global'

Vue.use(Vuex)

const plugins = [
  createPersistedState({
    paths: ['dnaPanels', 'proteinPanels', 'customGenomes', 'userId'],
    key: env.VUE_APP_PERSIST_KEY,
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) =>
        Cookies.set(key, value, {
          expires: 3
          /* secure: true */
        }),
      removeItem: key => Cookies.remove(key)
    }
  })
]

export const createStore = (): Store<RootState> =>
  new Vuex.Store({
    actions: {},
    modules: {
      panels,
      session
    },
    mutations: {},
    plugins
  })

const store = createStore()

export default store
