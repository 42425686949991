import { AxiosRequestConfig } from 'axios'

const config = (baseURL: string): AxiosRequestConfig => {
  return {
    // `url` is the server URL that will be used for the request
    // url: '/user',

    // `method` is the request method to be used when making the request
    // method: 'get', // default

    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    baseURL,

    // `headers` are custom headers to be sent
    headers: {
      // 'X-Requested-With': 'XMLHttpRequest'
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
}

export default config
