import { Panel } from '@/types'

export function searchPanels<T extends Panel>(
  items: T[],
  searchKeyword: string
): T[] {
  if (searchKeyword === '') {
    return items
  }
  const keyword = searchKeyword.toLowerCase()
  const searchItems = items.filter(
    item =>
      (item.panel_id &&
        item.panel_id.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.panel_name &&
        item.panel_name.toLowerCase().indexOf(keyword) > -1) ||
      (item.panel_status &&
        item.panel_status.toLowerCase().indexOf(keyword) > -1) ||
      (item.panel_type &&
        item.panel_type.toLowerCase().indexOf(keyword) > -1) ||
      (item.panel_source &&
        item.panel_source.toLowerCase().indexOf(keyword) > -1) ||
      (item.genome && item.genome.toLowerCase().indexOf(keyword) > -1) ||
      (item.num_lineitems &&
        item.num_lineitems.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.num_amplicons &&
        item.num_amplicons.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.pct_coverage &&
        item.pct_coverage.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.validated &&
        item.validated.toString().toLowerCase().indexOf(keyword) > -1) ||
      (item.date_last_modified &&
        item.date_last_modified.toLowerCase().indexOf(keyword) > -1)
  )
  return searchItems
}
