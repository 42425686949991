import downloadPanelDetailsZip from '@/api/downloadPanelDetailsZip'
import { SubmitWithPanelData } from '@/types'

export default async function ({
  panelId,
  panelName,
  panelType
}: SubmitWithPanelData): Promise<void> {
  const fileName = `Tapestri-Designer-results-${
    panelType?.toLocaleLowerCase() === 'catalog' ? panelName : panelId
  }.zip`

  const { data } = await downloadPanelDetailsZip({
    panelId
  })
  const link = document.createElement('a')
  if (window.navigator.msSaveOrOpenBlob) {
    link.onclick = function (): void {
      const blobObject = new Blob([data])
      window.navigator.msSaveOrOpenBlob(blobObject, fileName)
      window.navigator.msSaveOrOpenBlob(blobObject, fileName)
    }
  } else {
    const url = window.URL.createObjectURL(new Blob([data]))
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
  }
  link.click()
}
