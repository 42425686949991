// Api
import authApi from '@/services/authApi'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { GetUserDataInput, UserPermissionsResponse } from '@/types'

export default function ({
  portalId
}: GetUserDataInput): Promise<AxiosResponse<UserPermissionsResponse>> {
  return authApi.get(`/permissions/${portalId}`, {
    headers: authHeaders()
  })
}
