// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { DNAPanel, GetSummaryOfAllCustomPanelsOfUserInput } from '@/types'

export default function ({
  userId
}: GetSummaryOfAllCustomPanelsOfUserInput): Promise<AxiosResponse<DNAPanel[]>> {
  // Use id 4 to get custom panels
  return api.get(`/users/${userId}/custom-panels`, {
    headers: authHeaders()
  })
}

// NEED PANEL ID TO BE RETURNED

// Success returns 200
// [
//     {
//         "panel_name": "AML",
//         "num_lineitems": 50,
//         "num_amplicons": 50,
//         "pct_coverage": 100,
//         "date_last_modified": "2018-11-21 08:35:06"
//     },
//     {
//         "panel_name": "THP",
//         "num_lineitems": 609,
//         "num_amplicons": 244,
//         "pct_coverage": 100,
//         "date_last_modified": "2018-11-21 08:35:06"
//     }
// ]
// Error returns
// {
//  "status": "error"
//  "details": <error msg>
// }
