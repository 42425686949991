





















































































import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import CustomDashboardTableBody from '@/components/CustomDashboardTableBody.vue'
import TableHeaderTabs from '@/components/TableHeaderTabs.vue'
import TableHeader from '@/components/TableHeader.vue'
import { DefaultPerPage } from '@/constants/global'
import {
  PanelsTab,
  TableColumns,
  DNAPanel,
  GenomeReferenceFiles
} from '@/types'

@Component({
  name: 'CustomDashboardTable',
  components: {
    CustomDashboardTableBody,
    TableHeaderTabs,
    TableHeader
  }
})
export default class CustomDashboardTable extends Vue {
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: true }) isRowEllipsis!: boolean
  @Prop({ default: true }) isRowEditable!: boolean
  @Prop({ default: false }) checkable!: boolean
  @Prop({ default: false }) draggable!: boolean
  @Prop({ default: false }) isRowRemovable!: boolean
  @Prop({ default: true }) paginated!: boolean
  @Prop({ type: Array, default: () => [] }) customPanels!: DNAPanel[]
  @Prop() private customGenomes!: GenomeReferenceFiles
  @Prop() private customGenomeLoading!: boolean
  @Prop() private customPanelLoading!: boolean
  @Prop() private currentRouteName!: string
  @Prop({ default: false }) private adminLoggedIn!: boolean
  @Prop() private customPanelColumns!: TableColumns
  @Prop() private genomeColumns!: TableColumns

  public perPage: number = DefaultPerPage
  public stickyHeaders = true
  // Tabs
  public activeTab = 'custom_panel'
  public totalShipPanel = 0
  public totalBuiltPanel = 0

  public panelsTab: PanelsTab[] = [
    {
      id: 'custom_panel',
      label: 'Custom Panels',
      size: 0
    },
    {
      id: 'custom_genome',
      label: 'Custom Genomes',
      size: 0
    }
  ]

  @Emit('searchKeyword')
  public onSearchItems(searchKeyword: string): string {
    return searchKeyword
  }

  public onChangePerPage(perPage: number): void {
    this.perPage = perPage
  }

  public countTabItems(): void {
    this.panelsTab[0].size = this.customPanels.length
    this.panelsTab[1].size = this.customGenomes.length
  }

  // mounted
  public mounted(): void {
    this.countTabItems()
    if (
      this.currentRouteName === '/custom-genomes' ||
      this.currentRouteName === '/customreferences' ||
      this.currentRouteName === '/customgenomes' ||
      this.currentRouteName === '/admin/custom-genomes'
    ) {
      this.activeTab = 'custom_genome'
    } else {
      this.activeTab = 'custom_panel'
    }
  }

  @Watch('customPanels')
  public customPanelsChange(): void {
    this.countTabItems()
    if (this.activeTab === 'custom_genome') {
      this.countTabItems()
    }
  }

  @Watch('customGenomes')
  public customGenomesChange(): void {
    this.countTabItems()
  }

  @Watch('currentRouteName')
  public changeCurrentRoute(val: string): void {
    if (val === '/custom-genomes' || val === '/admin/custom-genomes') {
      this.activeTab = 'custom_genome'
    } else {
      this.activeTab = 'custom_panel'
    }
  }

  /*eslint-disable */
  @Watch('activeTab')
  @Emit('changeActiveTab')
  public tabChange(): void {}
  /* eslint-enable */
  /*eslint-disable */
  @Emit('refresh')
  public refresh(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('createCustomPanelClick')
  public createCustomPanelClick(): void {}
  /* eslint-enable */

  @Emit('downloadCustomPanel')
  public downloadCustomPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('deletePanel')
  public deletePanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('shareModalPopup')
  public shareModalPopup(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('uploadCustomReferenceClick')
  public uploadCustomReferenceClick(): void {}
  /* eslint-enable */

  @Emit('copyPanel')
  public copyPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('downloadIdtFile')
  public downloadIdtFile(): void {}
  /* eslint-enable */
}
