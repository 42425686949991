// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { DeletePanelInput } from '@/types'

export default function ({
  panelId
}: DeletePanelInput): Promise<AxiosResponse> {
  return api.delete(`/panels/${panelId}`, {
    headers: authHeaders()
  })
}

// Success returns N/A
// Error returns
// {
//  "status": "error"
//  "details": <error msg>
// }
