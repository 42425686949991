import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { EsModuleComponent } from 'vue/types/options'
import Home from '@/pages/home/index.vue'
import AuthGuard from './middleware/authGuard'
import { env } from '@/constants/global'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    beforeEnter(): void {
      const currentUrl = encodeURIComponent(`${window.location.href}`)
      window.location.href = `${env.VUE_APP_PORTAL_URL}/login?logout=true&dredirect=${currentUrl}`
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: ['/custom-genomes', '/customreferences', '/customgenomes']
  },
  {
    alias: '/catalogpanels',
    path: '/catalog-panels',
    name: 'CatalogPanels',
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/catalogPanels/index.vue')
  },
  {
    path: '/catalogpanels/:panelLabel',
    name: 'CatalogPanelDetail',
    props: true,
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/catalogPanels/_id.vue')
  },
  {
    alias: '/custompanel',
    path: '/custom-panel/:panelId',
    name: 'CustomPanel',
    props: true,
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/customPanels/_id.vue')
  },
  {
    alias: '/customreference',
    path: '/upload-reference-files',
    name: 'CustomReference',
    props: true,
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/customReferences/new.vue')
  },
  {
    alias: '/custompanelcreate',
    path: '/custom-panel-create',
    name: 'CreateCustomPanel',
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/customPanels/new.vue')
  },

  {
    alias: ['/admin', '/admin/custom-genomes'],
    path: '/admin',
    name: 'Admin',
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/admin/index.vue')
  },

  {
    alias: '/admin/custom-panel-create',
    path: '/admin/custom-panel-create',
    name: 'AdminCreateCustomPanel',
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/admin/customPanels/new.vue')
  },
  {
    alias: '/admin/custompanel',
    path: '/admin/custom-panel/:panelId',
    name: 'AdminCustomPanel',
    props: true,
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/admin/customPanels/_id.vue')
  },
  {
    alias: '/admin/customreference',
    path: '/admin/upload-reference-files',
    name: 'AdminCustomReference',
    props: true,
    meta: { requiresAuth: true },
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/admin/customReferences/new.vue')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: (): Promise<EsModuleComponent> =>
      import('@/pages/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: env.BASE_URL,
  routes
})

router.beforeEach(AuthGuard)

export default router
