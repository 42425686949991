


































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import CustomCard from '@/components/CustomCard.vue'

@Component({
  name: 'PublicHomePageCard',
  components: {
    CustomCard
  }
})
export default class PublicHomePageCard extends Vue {
  @Prop() private title!: string
  @Prop() private subtitle!: string
  @Prop() private description!: string
  @Prop() private buttonText!: string
  @Prop() private height!: string
  @Prop() private width!: string

  /*eslint-disable */
  @Emit('navigate')
  public navigate(): void {}
  /* eslint-enable */
}
