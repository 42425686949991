import getAuthTokenFromCookie from '@/helpers/getAuthTokenFromCookie'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getAxiosHeaders(): any {
  const idToken = getAuthTokenFromCookie()
  if (idToken) {
    return {
      Authorization: `Bearer ${idToken}`
    }
  }

  return {}
}
