














































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import CustomDashboardWithTable from '@/components/CustomDashboardWithTable.vue'
import PublicDashboard from '@/components/PublicDashboard.vue'
import { DNAPanel, GenomeReferenceFiles } from '@/types'

@Component({
  name: 'HomePageContent',
  components: {
    PageHeader,
    PublicDashboard,
    CustomDashboardWithTable
  }
})
export default class HomePageContent extends Vue {
  @Prop() private loading!: boolean
  @Prop() private title!: string
  @Prop() private subtitle!: string
  @Prop({ default: false }) private isAuthenticated!: boolean
  @Prop() customPanels!: DNAPanel[]
  @Prop() customGenomes!: GenomeReferenceFiles
  @Prop() private panelIsLoaded!: boolean
  @Prop() private customGenomeLoading!: boolean
  @Prop() private customPanelLoading!: boolean
  @Prop() private currentRouteName!: string

  public catalogTitle = 'Catalog Panel'
  public catalogSubtitle = 'Browse our catalog of predesigned panels'
  public catalogDescription =
    'We offer a list of single-cell DNA and Protein panels that are predesigned for several applications, including chronic lymphocytic leukemia (CLL), acute myeloid leukemia (AML), and other myeloid disorders. Some of these panels have already been manufactured and are available immediately.'

  public customTitle = 'Custom Panel'
  public customSubtitle = 'Create a custom panel'
  public customDescription =
    'Modify an existing panel or design one from scratch using our proprietary primer design pipeline. Custom panels allow you to design amplicons for any targets in the hg19, mm10, or any other genome of interest.'

  @Emit('navigate')
  public navigate(name: string): string {
    return name
  }

  @Emit('searchKeyword')
  public searchKeyword(searchKeyword: string): string {
    return searchKeyword
  }

  /*eslint-disable */
  @Emit('refresh')
  public refresh(): void {}
  /* eslint-enable */

  @Emit('downloadCustomPanel')
  public downloadCustomPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('deletePanel')
  public deletePanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('shareModalPopup')
  public shareModalPopup(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('uploadCustomReferenceClick')
  public uploadCustomReferenceClick(): void {}
  /* eslint-enable */

  @Emit('copyPanel')
  public copyPanel(panel: DNAPanel): DNAPanel {
    return panel
  }
  /*eslint-disable */
  @Emit('changeActiveTab')
  public changeActiveTab(): void {}
  /* eslint-enable */
}
