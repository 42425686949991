








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PageHeader'
})
export default class PageHeader extends Vue {
  @Prop() private title!: string
  @Prop() private subtitle!: string
}
