// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { ApiResponse, DNAPanel } from '@/types'

type AdminPanelsResponse = ApiResponse<DNAPanel[]>

export default function (): Promise<AxiosResponse<AdminPanelsResponse>> {
  return api.get('/admin/panels', {
    headers: authHeaders()
  })
}
