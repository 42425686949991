

































































































































































































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import ValidationStatus from '@/components/ValidationStatus.vue'
import ContextMenu from '@/components/ContextMenu.vue'
import PanelStatus from '@/components/PanelStatus.vue'
import { DefaultPerPage } from '@/constants/global'
import { FileActionOptions, DNAPanel, ProcessData, TableColumns } from '@/types'

@Component({
  name: 'CustomDashboardTableBody',
  components: {
    ValidationStatus,
    ContextMenu,
    PanelStatus
  }
})
export default class CustomDashboardTableBody extends Vue {
  @Prop({ default: false }) checkable!: boolean
  @Prop({ default: () => [] }) checkedItems!: ProcessData
  @Prop({ required: true }) columns!: TableColumns
  @Prop() defaultSort!: string[]
  @Prop({ default: false }) draggable!: boolean
  @Prop({ default: true }) hoverable!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: false }) isPaginationSimple!: boolean
  @Prop() isRowEllipsisActions!: FileActionOptions | null
  @Prop({ default: () => (): boolean => true }) isRowCheckable!: (
    row: ProcessData
  ) => void

  @Prop({ default: false }) isRowEditable!: boolean
  @Prop({ default: false }) isRowRemovable!: boolean
  @Prop({ default: false }) isRowDownlodable!: boolean
  @Prop({ default: false }) isRowActing!: boolean
  @Prop({ default: false }) isRowActSuccess!: boolean
  @Prop({ required: true }) items!: ProcessData[]
  @Prop({ default: true }) paginated!: boolean
  @Prop({ default: 'bottom' }) paginationPosition!: string
  @Prop({ default: DefaultPerPage }) perPage!: number
  @Prop({ default: true }) stickyHeaders!: boolean
  @Prop({ default: false }) adminLoggedIn!: boolean

  public sortIcon = 'arrow-up'
  public sortIconSize = 'is-small'
  public itemToEdit: ProcessData | null = null
  public itemFieldToEdit = ''

  @Emit('downloadCustomPanel')
  public downloadCustomPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('deletePanel')
  public deletePanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('shareModalPopup')
  public shareModalPopup(panel: DNAPanel): DNAPanel {
    return panel
  }

  @Emit('copyPanel')
  public copyPanel(panel: DNAPanel): DNAPanel {
    return panel
  }

  /*eslint-disable */
  @Emit('downloadIdtFile')
  public downloadIdtFile(): void {}
  /* eslint-enable */
}
