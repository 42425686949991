













































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { DNAPanel, ShareFormData } from '@/types'

@Component({
  name: 'SharePanelModal'
})
export default class SharePanelModal extends Vue {
  @Prop({ default: false }) isShareModalActive!: boolean
  @Prop() panel!: DNAPanel
  @Prop() shareData!: ShareFormData
  @Prop() btnActionLoading!: boolean

  $refs!: {
    addFileForm: InstanceType<typeof ValidationObserver>
  }

  get isShareModalActiveValue(): boolean {
    return this.isShareModalActive
  }

  set isShareModalActiveValue(val: boolean) {
    this.$emit('input', val)
  }

  public async onSubmit(): Promise<void> {
    const success = await this.$refs.addFileForm.validate()
    if (success) {
      this.$emit('sharePanel', this.panel)
    }
  }

  /*eslint-disable */
  @Emit('cancel')
  public cancel(): void {}
  /* eslint-enable */
}
