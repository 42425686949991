





































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Footer'
})
export default class Footer extends Vue {
  get currentYear(): number {
    const d = new Date()
    return d.getFullYear()
  }
}
