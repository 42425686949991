import { AxiosError } from 'axios'
import DesignError from '@/classes/DesignError'

export default function convertToDesignError(error: AxiosError): DesignError {
  if (error.response && error.response.status) {
    return DesignError.NewFromAxios(error)
  }

  return new DesignError(error)
}
