import { AxiosError } from 'axios'
import UUID from '@/classes/UUID'
import { DesignErrorResponse } from '@/types'

export default class DesignError extends Error {
  private _name = 'DesignError'
  private _response: DesignErrorResponse = { status: 500 }
  private _uuid: UUID

  constructor(error: Error | string) {
    super(DesignError.InitErrorMessage(error))
    this._uuid = new UUID()
  }

  get response(): DesignErrorResponse {
    return this._response
  }

  set response(response: DesignErrorResponse) {
    if (response) {
      this._response = response
    }
  }

  get status(): number | null {
    if (this._response && this._response.status) {
      return this._response.status
    }
    return null
  }

  get uuid(): UUID {
    return this._uuid
  }

  static NewFromAxios(error: AxiosError): DesignError {
    const e = new DesignError(
      (error.response &&
        error.response.data &&
        (error.response.data.error || error.response.data.message)) ||
        error.toString()
    )
    if (error.response) {
      e.response = error.response
    }
    return e
  }

  static InitErrorMessage(error: Error | string): string {
    if (typeof error === 'string' || error instanceof String) {
      return String(error)
    }
    return error.message
  }
}
