























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import PanelModal from '@/components/PanelModal.vue'
import DeleteSVG from '@/assets/icons/delete.svg'
import { DNAPanel } from '@/types'

@Component({
  name: 'DeletePanelModal',
  components: {
    PanelModal,
    DeleteSVG
  }
})
export default class DeletePanelModal extends Vue {
  @Prop() isConfirmModalActive!: boolean
  @Prop() panel!: DNAPanel

  get isConfirmModalActiveValue(): boolean {
    return this.isConfirmModalActive
  }

  set isConfirmModalActiveValue(val: boolean) {
    this.$emit('input', val)
  }
  /*eslint-disable */
  @Emit('closeDeleteModal')
  public closeDeleteModal(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('deletePanelData')
  public deletePanelData(): void {}
  /* eslint-enable */
}
