import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export function getLocalTimeFormatted(timeUTC: string): string {
  return timeUTC && dayjs(timeUTC).isValid()
    ? dayjs
        .utc(timeUTC.substring(0, 23))
        .local()
        .format('MM/DD/YYYY - HH:mm:ss')
    : timeUTC
}
