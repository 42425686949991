


















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import HomePageContent from '@/components/HomePageContent.vue'
import SharePanelModal from '@/components/SharePanelModal.vue'
import DeletePanelModal from '@/components/DeletePanelModal.vue'
import EditPanelModal from '@/components/EditPanelModal.vue'
import * as Segment from '@/services/analytics'
import copyPanel from '@/api/copyPanel'
import sharePanel from '@/api/sharePanel'
import isLoggedIn from '@/helpers/isLoggedIn'
import showSnackbar from '@/helpers/showSnackbar'
import getAuthTokenFromCookie from '@/helpers/getAuthTokenFromCookie'
import downloadPanelDetailsZip from '@/helpers/downloadPanelDetailsZip'
import { searchPanels } from '@/helpers/searchPanels'
import { searchFiles } from '@/helpers/searchFiles'
import {
  DNAPanel,
  GenomeReferenceFiles,
  ShareFormData,
  LineItemType,
  UserIdPayload,
  DeletePanelInput,
  GenomeReferenceFile,
  CreatePanelFormData
} from '@/types'

const Panels = namespace('panels')
const Session = namespace('session')

@Component({
  components: {
    HomePageContent,
    SharePanelModal,
    DeletePanelModal,
    EditPanelModal
  }
})
export default class Home extends Vue {
  @Session.State
  public userId!: string | null

  @Session.Action
  public getUserId!: () => Promise<void>

  @Panels.Action
  public getUserPanels!: (data: UserIdPayload) => Promise<void>

  @Panels.Action
  public getCustomGenomes!: () => Promise<void>

  @Panels.Action
  public deleteCustomPanel!: (data: DeletePanelInput) => Promise<void>

  @Panels.Getter
  public dnaCustomPanels!: DNAPanel[]

  @Panels.Getter
  public dnaPanels!: DNAPanel[]

  @Panels.Getter
  public customGenomes!: GenomeReferenceFiles

  public loading = false
  public customPanelLoading = false
  public customGenomeLoading = false
  public isAuthenticated = false
  public subtitle =
    'Design primers using human, mouse, and other reference genomes for variant analysis including SNVs, indels, CNVs and translocations'

  public editPanelModalActive = false
  public currentRouteName = ''
  public page = 'home'
  public btnShareLoading = false
  public panel: DNAPanel | null = null
  public isShareModalActive = false
  public isConfirmModalActive = false
  public visibleLineItems = 'all'
  public lineItemTypes: LineItemType[] = []
  public windowWidth = window.innerWidth
  public searchKeyword = ''
  public activeTab = 'custom_panel'
  public filteredData: GenomeReferenceFiles = []
  public createPanelData: CreatePanelFormData = {
    name: '',
    description: ''
  }

  public shareData: ShareFormData = {
    emails: [],
    notes: ''
  }

  public closeEditPanelForm(): void {
    this.editPanelModalActive = false
  }

  public clearShareData(): void {
    this.shareData.emails = []
    this.shareData.notes = ''
  }

  public closeShareModal(): void {
    this.isShareModalActive = false
    this.clearShareData()
  }

  get authCookie(): string | null {
    return getAuthTokenFromCookie()
  }

  get panelIsLoaded(): boolean {
    return isLoggedIn()
  }

  get customPanelTableLoader(): boolean {
    return this.dnaCustomPanels.length < 1 && this.customPanelLoading
  }

  get customReferenceTableLoader(): boolean {
    return this.customGenomes.length < 1 && this.customGenomeLoading
  }

  get filteredCustomPanels(): DNAPanel[] {
    if (this.searchKeyword) {
      if (this.activeTab === 'custom_panel') {
        return searchPanels(this.dnaCustomPanels, this.searchKeyword)
      }
    }
    return this.dnaCustomPanels
  }

  get filteredCustomGenomes(): GenomeReferenceFiles {
    if (this.searchKeyword) {
      if (this.activeTab === 'custom_genome') {
        const result = searchFiles(
          this.processedCustomGenomes as GenomeReferenceFiles,
          this.searchKeyword
        )
        return result
      }
    }
    return this.processedCustomGenomes
  }

  public onSearch(val: string): void {
    this.searchKeyword = val
  }

  get processedCustomGenomes(): GenomeReferenceFiles {
    const data = this.customGenomes.map((g: GenomeReferenceFile) => ({
      ...g,
      fasta: g.files.fasta,
      dbSNP: g.files.dbSNP,
      upload_status: this.uploadStatus(g.upload_status)
    }))
    return data
  }

  public uploadStatus(status: string): string {
    if (status === 'ready') {
      return 'Completed'
    } else if (status === 'failed') {
      return 'Failed'
    } else if (status === 'uploading') {
      return 'In Progress'
    }
    return ''
  }

  public changeActiveTab(tab: string): void {
    if (tab === 'custom_panel') {
      this.activeTab = 'custom_panel'
      /*eslint-disable */
      this.$router.replace('/').catch(() => {})
      /* eslint-enable */
    } else {
      this.activeTab = 'custom_genome'
      /*eslint-disable */
      this.$router.replace('/custom-genomes').catch(() => {})
      /* eslint-enable */
    }
  }

  public async fetchCustomGenomes(): Promise<void> {
    this.customGenomeLoading = true
    if (this.userId && this.authCookie) {
      await this.getCustomGenomes()
    }
    this.customGenomeLoading = false
  }

  public async fetchCustomPanels(): Promise<void> {
    this.customPanelLoading = true
    if (this.userId && this.authCookie) {
      await this.getUserPanels({
        userId: this.userId.toString()
      })
    }
    this.customPanelLoading = false
  }

  public async refresh(): Promise<void> {
    this.loading = true
    await this.fetchCustomPanels()
    await this.fetchCustomGenomes()
    this.loading = false
  }

  public async downloadCustomPanel(panel: DNAPanel): Promise<void> {
    this.loading = true
    if (this.authCookie && panel.panel_name && panel.panel_id) {
      try {
        Segment.track('Downloaded custom panel files', {
          panelId: panel.panel_id,
          userId: this.userId
        })
        await downloadPanelDetailsZip({
          panelName: panel.panel_name,
          panelType: panel.panel_type,
          panelId: panel.panel_id
        })
      } catch (error) {
        showSnackbar({ message: error.message })
      }
    }
    this.loading = false
  }

  public deletePanel(panel: DNAPanel): void {
    this.panel = panel
    this.isConfirmModalActive = true
  }

  public async deletePanelData(): Promise<void> {
    this.loading = true
    this.isConfirmModalActive = false
    try {
      if (this.authCookie && this.panel?.panel_id) {
        Segment.track('Deleted custom panel', {
          panelId: this.panel.panel_id,
          userId: this.userId
        })
        await this.deleteCustomPanel({
          panelId: this.panel.panel_id
        })
        showSnackbar({ message: 'Panel has been deleted', type: 'is-success' })
      }
    } catch (_) {}
    this.loading = false
  }

  public async copyPanelData(): Promise<void> {
    this.editPanelModalActive = false
    this.loading = true
    const panelName = this.createPanelData.name
    if (this.authCookie && this.panel && this.panel.panel_id) {
      try {
        Segment.track('Custom panel copied', {
          panelId: this.panel.panel_id,
          userId: this.userId
        })
        const data = await copyPanel({
          panelId: this.panel.panel_id,
          name: panelName,
          description: this.createPanelData.description || ''
        })
        localStorage.setItem(
          'description',
          this.createPanelData.description || ''
        )
        this.$router.push({
          name: 'CreateCustomPanel',
          query: {
            step: '3',
            panel_id: `${data.data.panel_id}`,
            copy: 'true'
          }
        })
      } catch (error) {
        showSnackbar({ message: error.message })
      }
    }
    this.loading = false
  }

  public copyPanel(panel: DNAPanel): void {
    this.editPanelModalActive = true
    this.createPanelData.name = `Copy of ${panel.panel_name}`
    this.createPanelData.description = panel.panel_description || ''
    this.panel = panel
  }

  public shareModalPopup(panel: DNAPanel): void {
    this.panel = panel
    this.isShareModalActive = true
  }

  public async onSharePanel(panel: DNAPanel): Promise<void> {
    this.btnShareLoading = true
    if (this.authCookie && panel.panel_id) {
      try {
        Segment.track('Shared custom panel quote', {
          panelId: panel.panel_id,
          userId: this.userId
        })
        await sharePanel({
          panelId: panel.panel_id,
          data: {
            users: this.shareData.emails,
            description: this.shareData.notes
          }
        })
        showSnackbar({
          message: 'Panel shared successfully',
          type: 'is-primary'
        })
      } catch (error) {
        showSnackbar({ message: error.message })
      }
    }
    this.btnShareLoading = false
    this.isShareModalActive = false
    this.clearShareData()
  }

  public closeConfirmDialog(): void {
    this.isConfirmModalActive = false
  }

  public uploadCustomReferenceClick(): void {
    this.$router.push({ name: 'CustomReference' })
  }

  public scrollToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  public navigate(name: string): void {
    this.$router.push({ name })
  }

  public async mounted(): Promise<void> {
    if (this.$router.currentRoute) {
      this.currentRouteName = this.$router.currentRoute.path
    }
    window.onresize = (): void => {
      this.windowWidth = window.innerWidth
    }
    this.scrollToTop()
    if (isLoggedIn()) {
      if (this.authCookie && this.userId === null) {
        this.getUserId()
      }
      this.isAuthenticated = true
      this.refresh()
    } else {
      this.isAuthenticated = false
    }
    if (this.page === 'home' && !this.isAuthenticated) {
      document.documentElement.style.overflow =
        this.windowWidth < 1023 ? 'auto' : 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }
  }

  updated(): void {
    this.currentRouteName = this.$route.path
  }

  @Watch('userId')
  public userIdFetched(val: string): void {
    if (val) {
      this.refresh()
    }
  }
}
