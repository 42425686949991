






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'DefaultSublayout'
})
export default class DefaultSublayout extends Vue {}
