import { WindowCookies } from '@/types'

declare global {
  interface Window {
    $cookies: WindowCookies
  }
}

export default (name: string): void => {
  if (window.$cookies && window.$cookies.remove && window.$cookies.get(name)) {
    window.$cookies.remove(name)
  } else {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}
