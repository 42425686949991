import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { ColorModifiers, GlobalPositions } from 'buefy/types/helpers'

export default function ({
  message = 'Unknown Error',
  type = 'is-danger' as ColorModifiers,
  position = 'is-top' as GlobalPositions,
  duration = 5000,
  queue = false,
  indefinite = false,
  onAction = (): void => {
    // do something
  }
} = {}): void {
  Snackbar.open({
    duration,
    message,
    type,
    position,
    actionText: 'x',
    queue,
    indefinite,
    onAction
  })
}
