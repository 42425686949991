// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { ApiResponse, GenomeReferenceFiles } from '@/types'

type AdminGenomeFilesResponse = ApiResponse<GenomeReferenceFiles>

export default function (): Promise<AxiosResponse<AdminGenomeFilesResponse>> {
  return api.get('/files', {
    headers: authHeaders()
  })
}
