import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import getUserData from '@/api/getUserData'
import getUserPermissions from '@/api/getUserPermissions'
import getAuthTokenFromCookie from '@/helpers/getAuthTokenFromCookie'
import getAuthTokenPortalId from '@/helpers/getAuthTokenPortalId'
import { SessionState, RootState } from '@/types'

const state: SessionState = {
  userId: null,
  permissions: []
}

const mutations: MutationTree<SessionState> = {
  SET_USER_ID(state, payload) {
    state.userId = payload
  },

  SET_USER_PERMISSIONS(state, payload) {
    state.permissions = payload
  }
}

const actions: ActionTree<SessionState, RootState> = {
  async getUserId(context) {
    const idToken = getAuthTokenFromCookie() || ''
    const portalId = getAuthTokenPortalId(idToken)
    const data = await getUserData({
      portalId
    })
    context.commit('SET_USER_ID', data.data.user_id)
  },

  async getUserPermissions(context) {
    const idToken = getAuthTokenFromCookie() || ''
    const portalId = getAuthTokenPortalId(idToken)
    const data = await getUserPermissions({
      portalId
    })
    context.commit('SET_USER_PERMISSIONS', data.data.permissions)
  }
}

const getters: GetterTree<SessionState, RootState> = {
  hasDesignerAdminAccess(state) {
    const hasDesignerAdminAccess = (state.permissions || []).find(
      p => p.permission_name === 'read:designer-admin'
    )
    if (hasDesignerAdminAccess) {
      return true
    }
    return false
  }
}

export const session: Module<SessionState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
