


























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'DesignerContextMenu'
})
export default class DesignerContextMenu extends Vue {
  @Prop() buttonLabel!: string
  @Prop({ default: '#0073FF' }) color!: string
  @Prop({ default: true }) textType!: boolean
  @Prop({ default: false }) loading!: boolean
  @Prop() panelStatus!: string
  @Prop() disabled!: boolean
}
