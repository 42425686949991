import { User, WindowAnalytics } from '@/types'

// A collection of functions to use for Segment.io
declare global {
  interface Window {
    analytics: WindowAnalytics
  }
}

const analytics = window.analytics
  ? (): WindowAnalytics => window.analytics
  : null

const identify = (userId: string, user: User): void => {
  if (analytics) {
    analytics().identify(userId, {
      email: user.email, // Required
      createdAt: user.created_at || '',
      ipAddress: user.last_ip || '',
      logins_count: user.logins_count,
      firstName: user.user_metadata.firstName || '',
      lastName: user.user_metadata.lastName || '',
      company: user.user_metadata.company || '',
      country: user.user_metadata.country || '',
      state: user.user_metadata.state || '',
      phone: user.user_metadata.phone || ''
    })
  }
}

const track = (event: string, data = {}): void => {
  if (analytics) {
    Object.keys(data).length
      ? analytics().track(event, { ...data })
      : analytics().track(event)
  }
}

export { analytics, identify, track }
