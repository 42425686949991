










































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CreateCustomPanelForm from '@/components/CreateCustomPanelForm.vue'
import { CreatePanelFormData } from '@/types'

@Component({
  name: 'EditPanelModal',
  components: {
    CreateCustomPanelForm
  }
})
export default class EditPanelModal extends Vue {
  @Prop() private editPanelModalActive!: boolean
  @Prop() private createPanelData!: CreatePanelFormData

  get editPanelModalActiveValue(): boolean {
    return this.editPanelModalActive
  }

  set editPanelModalActiveValue(val: boolean) {
    this.$emit('toggleModal', val)
  }

  /*eslint-disable */
  @Emit('editPanelFormCancel')
  public editPanelFormCancel(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('editPanelSaveClick')
  public editPanelSaveClick(): void {}
  /* eslint-enable */
}
