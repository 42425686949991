









import { Component, Prop, Vue } from 'vue-property-decorator'
import BadgedTitle from '@/components/BadgedTitle.vue'

@Component({
  components: {
    BadgedTitle
  },
  name: 'Badge'
})
export default class Badge extends Vue {
  @Prop({ required: true }) text!: string
  @Prop({ required: true }) badgeContent!: number
  @Prop({ default: false }) active!: boolean
}
