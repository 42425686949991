import Vue, { VNode } from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/scss/main.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueEllipseProgress from 'vue-ellipse-progress'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed, email } from 'vee-validate/dist/rules'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
// internal icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DateFormatter from '@/filters/dateFormatter'
import FileSizerFormatter from '@/filters/fileSizeFormatter'
import panelSizeFormatter from '@/filters/panelSizeFormatter'

library.add(fas)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
    fas: {
      sizes: {
        default: 'lg',
        'is-small': '1x',
        'is-medium': '2x',
        'is-large': '3x'
      }
    }
  }
})

// vee validate rules
// Add a rule.
extend('required', {
  ...required,
  message: 'This field is required.'
})

extend('email', {
  ...email,
  message: 'This field must be a valid email.'
})

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match.'
})

Vue.use(VueEllipseProgress)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('VSelect', VSelect)

// filters
Vue.filter('formatDate', DateFormatter)
Vue.filter('formatSize', FileSizerFormatter)
Vue.filter('panelSizeFormatter', panelSizeFormatter)

new Vue({
  router,
  store,
  render: (h): VNode => h(App)
}).$mount('#app')
