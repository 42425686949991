// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { DNAPanel, PostCopyPanelInput } from '@/types'

export default function ({
  panelId,
  name,
  description
}: PostCopyPanelInput): Promise<AxiosResponse<DNAPanel>> {
  return api.post(
    `/panels/${panelId}/copy-targets`,
    {
      description,
      name
    },
    {
      headers: authHeaders()
    }
  )
}

// Success returns 201 no body
// Error returns
//  {
//    "status": "Error",
//    "details": "portal_id is missing in the input"
//  }
