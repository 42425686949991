

























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'PanelModal'
})
export default class PanelModal extends Vue {
  @Prop() private isModalActive!: boolean
  @Prop() private title!: string
  @Prop() private confirmBtnLabel!: string
  @Prop({ default: 'Cancel' }) private closeBtnLabel!: string

  get isModalActiveValue(): boolean {
    return this.isModalActive
  }

  set isModalActiveValue(value: boolean) {
    this.$emit('input', value)
  }

  /*eslint-disable */
  @Emit('closeConfirm')
  public closeConfirm(): void {}
  /* eslint-enable */

  /*eslint-disable */
  @Emit('action')
  public action(): void {}
  /* eslint-enable */
}
