










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'CustomCard'
})
export default class CustomCard extends Vue {
  @Prop({ default: '#ffffff' }) background!: string
}
