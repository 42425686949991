import { Route, NavigationGuardNext } from 'vue-router'
import isLoggedIn from '@/helpers/isLoggedIn'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (to: Route, _from: Route, next: NavigationGuardNext): void => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isLoggedIn()) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
}
