import numeral from 'numeral'
export default (size: string): string => {
  let panelSize = '-'
  if (Number(size) > 999999) {
    panelSize = numeral(size).format('0.0 a') + 'b'
  } else if (Number(size) > 9999) {
    panelSize = numeral(size).format('00.0 a') + 'b'
  } else if (Number(size) > 999) {
    panelSize = numeral(size).format('0.0 a') + 'b'
  } else if (Number(size) <= 999) {
    panelSize = size + ' bp'
  }
  return panelSize
}
