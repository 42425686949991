











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CustomCard from '@/components/CustomCard.vue'

@Component({
  name: 'PrivateHomePageCard',
  components: {
    CustomCard
  }
})
export default class PrivateHomePageCard extends Vue {
  @Prop() private title!: string
  @Prop() private subtitle!: string
  @Prop() private buttonText!: string
  @Prop({ default: 'auto' }) private height!: string
  @Prop({ default: 'auto' }) private width!: string

  /*eslint-disable */
  @Emit('navigate')
  public navigate(): void {}
  /* eslint-enable */
}
