import getAuthTokenFromCookie from '@/helpers/getAuthTokenFromCookie'
import isTokenExpired from '@/helpers/isAuthTokenExpired'

export default (): boolean => {
  const idToken = getAuthTokenFromCookie()
  if (idToken) {
    const isExpired = isTokenExpired(idToken)
    return !isExpired
  }
  return false
}
