



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'BadgedTitle'
})
export default class BadgedTitle extends Vue {
  @Prop({ required: true }) text!: string
  @Prop({ default: 'has-text-weight-bold' }) textWeight!: string
  @Prop({ required: true }) badgeContent!: number
  @Prop({ default: false }) active!: boolean
}
