










































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { CreatePanelFormData } from '@/types'

@Component({
  name: 'CreateCustomPanelForm'
})
export default class CreateCustomPanelForm extends Vue {
  @Prop() createPanelData!: CreatePanelFormData
  @Prop({ default: false }) modalUse!: boolean

  /*eslint-disable */
  @Emit('onSubmit')
  public onSubmit(): void {}
  /* eslint-enable */
}
