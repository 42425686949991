























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Badge from '@/components/Badge.vue'
import { PanelsTab } from '@/types'

@Component({
  name: 'TableHeaderTabs',
  components: {
    Badge
  }
})
export default class TableHeaderTabs extends Vue {
  @Prop() tabs!: PanelsTab[]
  @Prop() activeTab!: string

  private sliderWidth = '7rem'

  get activeTabValue(): string {
    return this.activeTab
  }

  set activeTabValue(val: string) {
    this.$emit('input', val)
  }

  @Watch('activeTab')
  activeTabChange(val: string): void {
    this.setSliderWidth(val)
  }

  public setSliderWidth(activeTab: string): void {
    const width = document.getElementById(activeTab)?.clientWidth
    this.sliderWidth = `${width}px`
  }

  public mounted(): void {
    this.$nextTick(function () {
      this.setSliderWidth(this.tabs[0].id)
    })
  }
}
