// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { DeletePanelInput } from '@/types'

export default function ({
  panelId
}: DeletePanelInput): Promise<AxiosResponse> {
  return api.delete(`/admin/panels/${panelId}`, {
    headers: authHeaders()
  })
}
