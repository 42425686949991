



















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'NavBarDropdown'
})
export default class NavBarDropdown extends Vue {
  @Prop({ required: true }) userName!: string
  @Prop({ default: false }) hasDesignerAdminAccess!: boolean

  /*eslint-disable */
  @Emit('logout')
  public logout(): void {}
  /*eslint-anable */
}
