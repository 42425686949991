// Api
import api from '@/services/api'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { GetPanelDetailsZipInput } from '@/types'

export default function ({
  panelId
}: GetPanelDetailsZipInput): Promise<AxiosResponse<BlobPart>> {
  return api.get(`/panels/${panelId}/results-zip-file`, {
    headers: {
      Accept: 'application/zip',
      ...authHeaders()
    },
    responseType: 'blob'
  })
}

// Success returns 200
// 'Content-Type: application/zip'
// 'Content-Disposition: attachment; filename="<filename>"'
// Error returns
// {
//  "status": "error"
//  "details": <error msg>
// }
