import { SelectOptions } from '@/types'

export const DefaultPerPage = 10

export const PageOptions: SelectOptions = [
  {
    id: 10,
    name: '10 per page'
  },
  {
    id: 25,
    name: '25 per page'
  },
  {
    id: 50,
    name: '50 per page'
  },
  {
    id: 100,
    name: '100 per page'
  }
]

export const BooleanOptions: SelectOptions = [
  {
    id: 'no',
    name: 'NO'
  },
  {
    id: 'yes',
    name: 'YES'
  }
]

export const PANEL_SUBMISSION_AMPLICONS_LIMIT = 1000

class ProcessEnv {
  NODE_ENV = ''
  BASE_URL = '/' // workaround for tests not having BASE_URL set
  VUE_APP_AWS_ACCOUNT_ID = ''
  VUE_APP_AWS_STS_ROLE = ''
  VUE_APP_AWS_IDENTITY_POOL = ''
  VUE_APP_AWS_IDENTITY_PROVIDER = 'missionbio.auth0.com'
  VUE_APP_AWS_SDK_REGION = ''
  VUE_APP_AWS_UPLOAD_BUCKET = ''
  VUE_APP_PORTAL_URL = ''
  VUE_APP_PORTAL_COOKIE_NAME = ''
  VUE_APP_COOKIE_METADATA_URL = ''
  VUE_APP_DESIGNER_BACKEND_URL = ''
  VUE_APP_API_URL = ''
  VUE_APP_PERSIST_KEY = 'missionbio-designer'
}

class Container extends ProcessEnv implements Record<string, string> {
  [x: string]: string
}

const getConfiguration = (
  env: Record<string, string | undefined>
): ProcessEnv => {
  const result = new Container()
  for (const [key, defaultValue] of Object.entries(result)) {
    let value = Object.prototype.hasOwnProperty.call(env, key)
      ? env[key]
      : undefined
    if (value == null && defaultValue) {
      value = defaultValue
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (value == null && !(window as any).Cypress) {
      throw new Error(`'${key}' is not defined`)
    }
    result[key] = value as string
  }

  return result
}

export const env = getConfiguration(process.env)
