// Api
import authApi from '@/services/authApi'
import { AxiosResponse } from 'axios'
import authHeaders from '@/helpers/authHeaders'
import { GetUserProfileInput, User } from '@/types'

export default function ({
  userId
}: GetUserProfileInput): Promise<AxiosResponse<User>> {
  return authApi.get(`/users/${userId}`, {
    headers: authHeaders()
  })
}
